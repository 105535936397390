import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from './assets/js/request'
import './registerServiceWorker'
import router from './router'
import store from './store'
import moment from 'moment'

Vue.config.productionTip = false
Vue.prototype.axios = axios

// 定义一个全局过滤器实现日期格式化
Vue.filter('datefmt', function (input, fmtstring) { // 当input为时间戳时，需转为Number类型
    // 使用momentjs这个日期格式化类库实现日期的格式化功能
    return moment(input).format(fmtstring)
})

Vue.use(ElementUI)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
